import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import {
  ViewState,
  EditingState,
  IntegratedEditing,
} from '@devexpress/dx-react-scheduler';

import {
  Scheduler,
  DayView,
  WeekView,
  ViewSwitcher,
  Toolbar,
  Appointments,
  AppointmentForm,
  AppointmentTooltip,
  AllDayPanel,
  ConfirmationDialog,
  DragDropProvider,
  DateNavigator,
  TodayButton,
} from '@devexpress/dx-react-scheduler-material-ui';

import Paper from '@material-ui/core/Paper';

import {
  fetchAppointments as _fetchAppointments,
  updateAppointment as _updateAppointment,
  updateScheduler as _updateScheduler,
} from './actions';

const BooleanEditor = props => {
  return <AppointmentForm.BooleanEditor {...props} readOnly />;
};

const AppBase = ({
  appointments = [],
  scheduler = {},
  fetchAppointments,
  updateAppointment,
  updateScheduler,
}) => {
  useEffect(() => {
    fetchAppointments();
    updateScheduler();

    const interval = setInterval(() => {
      fetchAppointments();
    }, 30000);
    return () => clearInterval(interval);
  }, [fetchAppointments, updateScheduler]);

  if (!scheduler) {
    return null;
  }

  const {
    currentDate,
    currentViewName,
  } = scheduler;

  const schedulerChange = (data, type = null) => {
    switch (type) {
      case 'currentDate': {
        updateScheduler({ scheduler: { currentDate: data } });
        break;
      }

      case 'viewName': {
        updateScheduler({ scheduler: { currentViewName: data } });
        break;
      }

      default: {
        return;
      }
    }
  };

  const saveAppointments = (data) => {
    const { changed } = data;

    if (changed) {
      const appointment = Object.keys(changed);
      updateAppointment(data, appointment[0]);
    } else {
      updateAppointment(data);
    }
  };

  return (
    <Paper>
      <Scheduler
        data={appointments}
        locale="nb-NO"
        // height={660}
      >
        <ViewState
          currentDate={currentDate}
          onCurrentDateChange={data => schedulerChange(data, 'currentDate')}
          currentViewName={currentViewName}
          onCurrentViewNameChange={data => schedulerChange(data, 'viewName')}
        />
        <EditingState
          onCommitChanges={saveAppointments}
        />
        <IntegratedEditing />
        <WeekView
          name="work-week"
          displayName="Week"
          excludedDays={[0, 6]}
          startDayHour={9}
          endDayHour={19}
        />
        <DayView />
        <Toolbar />
        <ViewSwitcher />
        <ConfirmationDialog />
        <DateNavigator />
        <TodayButton />
        <Appointments />
        <AppointmentTooltip
          showOpenButton
          showDeleteButton
        />
        <AllDayPanel />
        <DragDropProvider />
        <AppointmentForm booleanEditorComponent={BooleanEditor} />
      </Scheduler>
    </Paper>
  );
};

const mapStateToProps = state => {
  return {
    ...state.Appointments,
    ...state.Scheduler,
  };
};

const mapDispatchToProps = dispatch => ({
  fetchAppointments: () => {
    dispatch(_fetchAppointments());
  },
  updateAppointment: (data, id) => {
    dispatch(_updateAppointment(data, id));
  },
  updateScheduler: data => {
    dispatch(_updateScheduler(data));
  },
});

const App = connect(mapStateToProps, mapDispatchToProps)(AppBase);

export default App;

export { App };

const fetchAppointments = () => ({
  type: 'FETCH_APPOINTMENTS',
  payload: [],
});

const updateAppointment = (appointment, id) => ({
  type: 'UPDATE_APPOINTMENT',
  payload: { appointment, id },
});

export { fetchAppointments, updateAppointment };

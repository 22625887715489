const updateScheduler = scheduler => ({
  type: 'UPDATE_SCHEDULER',
  payload: {
    scheduler,
  },
});

export default updateScheduler;

export { updateScheduler };

import { call, put, takeLatest } from 'redux-saga/effects';
import * as api from '../../api';

function* updateAppointmentData(data) {
  try {
    const appointment = yield call(api.updateAppointment, data);
    yield put({ type: 'UPDATE_APPOINTMENT_SUCCESS', payload: { appointment } });
  } catch (error) {
    yield put({ type: 'UPDATE_APPOINTMENT_FAILED', error });
  }
}

function* updateAppointmentSaga() {
  yield takeLatest('UPDATE_APPOINTMENT', updateAppointmentData);
}

export default updateAppointmentSaga;

import { DateTime } from 'luxon';

const initData = ({
  currentDate = DateTime.local(),
  currentViewName = 'work-week',
} = {}) => {
  return {
    scheduler: {
      currentDate,
      currentViewName,
    },
  };
};

const initialState = initData();

const Scheduler = (state = initialState, action) => {
  const { type, payload: { scheduler = null, area } = {} } = action;

  switch (type) {
    case 'UPDATE_SCHEDULER': {
      if (!scheduler) {
        return state;
      }

      const newState = {
        ...state,
        scheduler,
      };

      return newState;
    }

    default: {
      return state;
    }
  }
};

export default Scheduler;

import { combineReducers } from 'redux';

import Appointments from './appointments';
import Scheduler from './scheduler';

const rootReducer = combineReducers({
  Appointments,
  Scheduler,
});

export default rootReducer;

const initialState = {
  appointments: [],
};

const Appointments = (state = initialState, action) => {
  const { type, payload: { appointments = [], appointment } = {} } = action;

  switch (type) {
    case 'FETCH_APPOINTMENTS_SUCCESS': {
      const mappedAppointments = appointments
        .map(({
          id,
          room = 'Aquarium',
          subject = '',
          owner = '',
          timeStart,
          timeEnd,
        }) => ({
          title: subject,
          startDate: timeStart,
          endDate: timeEnd,
          notes: owner,
          id,
          location: room,
        }));

      const newState = {
        ...state,
        appointments: mappedAppointments,
      };

      return newState;
    }

    case 'UPDATE_APPOINTMENT_SUCCESS': {
      if (!appointment) {
        return state;
      }

      return state;
    }

    default: {
      return state;
    }
  }
};

export default Appointments;

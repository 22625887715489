/* eslint-disable func-names */

import { call, spawn, all } from 'redux-saga/effects';

import fetchAppointmentsSaga from './fetch-appointments';
import updateAppointmentSaga from './update-appointment';

function* rootSaga() {
  const sagas = [fetchAppointmentsSaga, updateAppointmentSaga];

  yield all(
    sagas.map(saga =>
      spawn(function*() {
        while (true) {
          try {
            yield call(saga);
            break;
          } catch (e) {
            console.log(e); // eslint-disable-line no-console
          }
        }
      }),
    ),
  );
}

export default rootSaga;

import axios from 'axios';

const endpoint = 'https://api.coverks.no/v1';

const fetchAppointments = () =>
  axios
    .get(`${endpoint}/rooms/Aquarium/events`)
    .then(result => result.data)
    .then(result => result || []);

export default fetchAppointments;

export { fetchAppointments };

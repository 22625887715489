import { call, put, takeLatest } from 'redux-saga/effects';
import * as api from '../../api';

function* fetchAppointmentsData() {
  try {
    const appointments = yield call(api.fetchAppointments, null);
    yield put({ type: 'FETCH_APPOINTMENTS_SUCCESS', payload: { appointments } });
  } catch (error) {
    yield put({ type: 'FETCH_APPOINTMENTS_FAILED', error });
  }
}

function* fetchAppointmentsSaga() {
  yield takeLatest('FETCH_APPOINTMENTS', fetchAppointmentsData);
  yield takeLatest('UPDATE_APPOINTMENT_SUCCESS', fetchAppointmentsData);
  yield takeLatest('UPDATE_APPOINTMENT_FAILED', fetchAppointmentsData);
}

export default fetchAppointmentsSaga;

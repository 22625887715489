import axios from 'axios';

const endpoint = 'https://api.coverks.no/v1';

const clone = (obj) => Object.assign({}, obj);

const renameKey = (object, key, newKey) => {
  const clonedObj = clone(object);
  const targetKey = clonedObj[key];

  delete clonedObj[key];
  clonedObj[newKey] = targetKey;

  return clonedObj;
};

const updateAppointment = (action) => {
  const { payload: { appointment, id = null } = {} } = action;

  const {
    added,
    changed,
    deleted,
  } = appointment;

  if (added) {
    const {
      title = 'Reserved',
      notes = '',
      startDate,
      endDate,
    } = added;

    return axios
      .post(`${endpoint}/rooms/1/events`, {
        subject: title,
        owner: notes,
        room: 'Aquarium',
        startDate,
        endDate,
        type: 'regular',
      })
      .then(result => {
      const { data: { appointment: response = null } = {} } = result; // eslint-disable-line

        return response;
      })
      .then(result => result || false);
  }

  if (changed) {
    if (!id) {
      return;
    }

    let changes = changed[id];

    changes = renameKey(changes, 'title', 'subject');
    changes = renameKey(changes, 'notes', 'owner');
    changes = renameKey(changes, 'startDate', 'timeStart');
    changes = renameKey(changes, 'endDate', 'timeEnd');

    const update = {
      id,
      data: {
        ...changes,
      },
    };

    return axios
      .put(`${endpoint}/rooms/1/events`, update)
      .then(result => {
      const { data: { appointment: response = null } = {} } = result; // eslint-disable-line

        return response;
      })
      .then(result => result || false);
  }

  if (deleted !== undefined) {
    return axios
      .delete(`${endpoint}/rooms/1/events/${deleted}`)
      .then(result => {
      const { data: { appointment: response = null } = {} } = result; // eslint-disable-line

        return response;
      })
      .then(result => result || false);
  }

  return false;
};

export { updateAppointment };
